import React from 'react';
import { Box, Container, Stack, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/innovation.scss';

const Innovation = () => {
  return (
    <Layout>
      <SEO
        title="Nutrient Solutions Innovation to Solve Global Nutrient Challenges | Ostara"
        description="Learn how Ostara is developing patented, first-of-its-class technology to solve global nutrient challenges and feed the world, including a new method to produce fertilizer."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Nutrient Solutions Innovation"
      />
      <main className="innovation">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[5, 5, 5, 10]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Innovation</h1>
                  <p style={{ maxWidth: 550 }}>
                    Ostara is developing patented, first-of-its-class technology
                    to solve global nutrient challenges and feed the world.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/innovation-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Container
          w="90%"
          maxW={1022}
          padding={0}
          className="innovation-container"
        >
          <Box w="100%" className="innovation-box innovation-box--1">
            <StaticImage
              className="innovation-box-img"
              placeholder="transparent"
              src="../images/inno-img01.png"
              alt=""
            />
            <div className="innovation-box-contents">
              <h3>Introducing a New Method to Produce Fertilizer</h3>
              <Text className="innovation-content-text">
                Ostara was founded in 2005 after developing an innovative method
                of granulating struvite to repurpose as phosphate fertilizer.
              </Text>
              <div className="innovation-content-list">
                <Text className="innovation-content-text">
                  Solving challenges related to struvite buildup in water
                  treatment facilities.
                </Text>
                <Text className="innovation-content-text">
                  Transforming struvite into our patented Crystal Green
                  <sup>®</sup> Pearl Crop Driven Release™ fertilizer.
                </Text>
              </div>
            </div>
          </Box>

          <Box
            w="100%"
            className="innovation-box innovation-box--left innovation-box--2"
          >
            <StaticImage
              className="innovation-box-img"
              placeholder="transparent"
              src="../images/inno-img02.png"
              alt=""
            />
            <div className="innovation-box-contents">
              <h3>Spearheading Global Adoption of New Technology</h3>
              <Text className="innovation-content-text">
                We introduced our nutrient recovery technology to cities around
                the world, removing excess phosphorus from wastewaters and
                turning it into a saleable fertilizer.
              </Text>
              <div className="innovation-content-list">
                <Text className="innovation-content-text">
                  Providing a revenue source for water managers through
                  fertilizer sales.
                </Text>
                <Text className="innovation-content-text">
                  Now over 25 commercial installations worldwide and counting.
                </Text>
              </div>
            </div>
          </Box>

          <Box w="100%" className="innovation-box innovation-box--3">
            <StaticImage
              className="innovation-box-img"
              placeholder="transparent"
              src="../images/inno-img03.png"
              alt=""
            />
            <div className="innovation-box-contents">
              <h3>Driving Demand for Sustainable Fertilizer</h3>
              <Text className="innovation-content-text">
                We built demand for a sustainable and efficient fertilizer
                product that reduces the amount of phosphorus released into the
                environment through agriculture.
              </Text>
              <div className="innovation-content-list">
                <Text className="innovation-content-text">
                  Demand for the product throughout Canada, U.S., Europe and the
                  U.K. continues to increase as growers seek more sustainable
                  fertilizer options.
                </Text>
                <Text className="innovation-content-text">
                  Crystal Green Pearl is produced in SGN 90 and SGN 150.
                </Text>
              </div>
            </div>
          </Box>

          <Box w="100%" className="innovation-box innovation-box--left">
            <StaticImage
              className="innovation-box-img"
              placeholder="transparent"
              src="../images/inno-img04-v2.png"
              alt=""
            />
            <div className="innovation-box-contents">
              <h3>Expanding Production to Meet Demand</h3>
              <Text className="innovation-content-text">
                Increased interest and limited supply for the Crystal Green
                Pearl drove Ostara to recreate this innovative product in its
                own manufacturing facilities.
              </Text>
              <div className="innovation-content-list">
                <Text className="innovation-content-text">
                  Developing the first production facility to ever produce
                  granulated struvite ( Crystal Green<sup>®</sup> fertilizer) in
                  Fort Meade, FL.
                </Text>
                <Text className="innovation-content-text">
                  Launching an additional facility in St. Louis in 2022 that
                  will produce over 250,000 tons of Crystal Green fertilizer
                  annually.
                </Text>
                <Text className="innovation-content-text">
                  Crystal Green is available in SGN 300.
                </Text>
              </div>
            </div>
          </Box>
        </Container>

        <Box className="innovation-infographic">
          <StaticImage
            className="innovation-box-img timeline__desktop"
            placeholder="transparent"
            src="../images/innovation-timeline.png"
            alt="Our Path of Ongoing Innovation"
          />
          <StaticImage
            className="innovation-box-img timeline__mobile"
            placeholder="transparent"
            src="../images/timeline-mobile.png"
            alt="Our Path of Ongoing Innovation"
          />
        </Box>
        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Innovation;
